<template>
    <el-dialog title="修改备注" :visible="visible" width="400px" custom-class="truck-remark-dialog" @close="close">
        <el-input
        type="textarea"
        :autosize="{ minRows: 2, maxRows: 4}"
        placeholder="请输入备注"
        v-model="remark">
        </el-input>
        <template #footer>
            <el-button type="default" @click="close">取消</el-button>
            <el-button type="primary" @click="handleSaveRemark">保存</el-button>
        </template>
    </el-dialog>
</template>

<script>
import { updateTruckSubRemark } from '@/api/truckSubscribe';

export default {
    name: 'TruckRemarkDialog',
    data() { 
        return {
            visible: false,
            id: '',
            remark: ''
        }
    },
    methods: {
        open(data) {
            this.id = data.id
            this.remark = data.remark || '' 
            this.visible = true
        },
        close() { 
            this.visible = false
        },
        handleSaveRemark() { 
            updateTruckSubRemark({
                id: this.id,
                remark: this.remark
            }).then(() => {
                this.$message.success('备注更新成功！')
                this.$emit('confirm')
                this.close()
            })
        }
    }
}
</script>

<style lang="stylus">
.truck-remark-dialog{
    .el-dialog__body{
        padding: 10px 20px;
    }
}
</style>