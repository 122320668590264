<template>
    <Icon :icon="this.icon" :style="iconStyle"></Icon>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
    name: 'MyIcon',
    props: {
        size: {
            type:Number
        },
        color: {
            type:String
        },
        icon: {
            type:String
        }
    },
    components: {
        Icon
    },
    computed: {
        iconStyle() { 
            let cacheStyle = {};
            if (this.size) { 
                cacheStyle.fontSize = `${this.size}px`;
            }
            if (this.color) { 
                cacheStyle.color = this.color;
            }
            return cacheStyle
        }
    }
}
</script>