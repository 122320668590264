<template>
    <el-dialog title="提示" :visible="visible" custom-class="expired-dialog" width="500px" @close="close">
        <div style="font-weight: bold;margin-bottom: 8px;">检测到以下车牌订阅已失效：</div>
        <el-table :data="expiredList">
            <el-table-column label="车牌号" prop="truckLicense"></el-table-column>
            <el-table-column label="状态" prop="status">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 1" size="small" type="success">已重新订阅</el-tag>
                    <el-tag v-else type="danger" size="small">订阅失效</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" :disabled="scope.row.status === 1" :loading="loading" @click="() => handleReSub(scope)">重新订阅</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
import { getCarTrajectory } from '@/api/truckSubscribe';
export default {
    name: 'ExpiredDialog',
    data() { 
        return {
            expiredList: [],
            blno:'',
            visible: false,
            loading: false
        }
    },
    methods: {
        open({ list,blno}) { 
            this.visible = true;
            this.blno = blno;
            this.expiredList = list.map(item => { 
                return {
                    truckLicense: item
                }
            });
        },
        close() { 
            if (this.expiredList.length) { 
                this.$emit('refresh')
            }
            this.visible = false;
            this.expiredList = [];
            this.blno = ''
        },
        handleReSub({$index,row}) { 
            this.loading = true
            getCarTrajectory({
                truckLicense: row.truckLicense,
                blno: this.blno
            }).then(() => { 
                // 重新订阅成功改一下列表状态
                this.expiredList = this.expiredList.map((item, index) => { 
                    if (index === $index) { 
                        return {
                            ...item,
                            status:1
                        }
                    }
                    return item
                })
            }).finally(() => { 
                this.loading = false
            })
        }
    }
}
</script>

<style lang="stylus">
.expired-dialog{
    .el-dialog__body{
        padding-top: 0
    }
    .succ-font{
        color: '#67C23A'
    }
    .dis-font{
        color: '#c0c4cc'
    }
}
</style>